function closeContactPopup() {
    $('#not-available-email').foundation('close');
}

function contactFormRecaptchaCallback() {
    $('#contact-form [type="submit"]').prop("disabled", false);
}

function contactFormRecaptchaExpiredCallback() {
    $('#contact-form [type="submit"]').prop("disabled", true);
}

function resetCaptcha() {
    grecaptcha.reset();
    contactFormRecaptchaExpiredCallback();
}